import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid/Grid";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import { MismatchingOrderPaymentSum } from "../../../model/DashboardReports/MismatchingOrderPaymentSum";
import { ApiBackend } from "../../../providers/apibackend";
import LinearProgress from "@material-ui/core/LinearProgress";
import ManageOrder from "../../Orders/ManageOrder";
import RefreshIcon from "@material-ui/icons/Refresh";
import { vasaloppetDateTimeGetterFormatter } from "../../../utilities/date";
import { IconButton, Typography } from "@material-ui/core";

const OrdersWithPaidMismatchCard = (props: WithStyles) => {
    const [result, setResult] = useState<MismatchingOrderPaymentSum[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [editOrderId, setEditOrderId] = useState<string | null>(null);

    const api = new ApiBackend();

    useEffect(() => {
        updateList();
    }, []);

    const updateList = async () => {
        setLoading(true);
        const data = await api.getOrdersWithWrongPaymentSum();
        setResult(data);
        setLoading(false);
    };

    const handleItemClick = (id: string) => {
        setEditOrderId(id);
    };

    const handleEditClose = async () => {
        setEditOrderId(null);
    };

    const render = () => {
        const { classes } = props;

        return (<>
            <Card>
                <CardHeader className={classes.cardHeader}
                    title={<>
                        <Typography variant="h5" style={{ display: "inline" }}>Orderavvikelser</Typography>
                        <IconButton onClick={updateList}>
                            <RefreshIcon color="primary" />
                        </IconButton>
                    </>}
                />
                <CardContent>
                    <p>
                        Order där ordersumma inte matchar betalt belopp
                    </p>

                    {loading && <LinearProgress color="secondary" />
                    }
                    {result &&
                        <Grid container>
                            <Grid item xs={3} style={{ fontWeight: 'bold' }}>ID</Grid>
                            <Grid item xs={3} style={{ fontWeight: 'bold' }}>Summa</Grid>
                            <Grid item xs={3} style={{ fontWeight: 'bold' }}>Betalt</Grid>
                            <Grid item xs={3} style={{ fontWeight: 'bold' }}>Datum</Grid>
                            {result.map((o, idx) => {
                                return <Fragment key={"item_" + idx}>
                                    <Grid item xs={3} style={{ fontSize: 12, background: idx % 2 == 0 ? '' : '#efefef' }}>
                                        <a style={{ textDecoration: 'underline' }} href="#"
                                            onClick={() => {
                                                handleItemClick(o.id)
                                            }}
                                        >
                                            {o.publicId}
                                        </a>
                                    </Grid>
                                    <Grid item xs={3} style={{ fontSize: 12, background: idx % 2 == 0 ? '' : '#efefef' }}>
                                        {o.totalSum}
                                    </Grid>
                                    <Grid item xs={3} style={{ fontSize: 12, background: idx % 2 == 0 ? '' : '#efefef' }}>
                                        {o.paiedAmount}
                                    </Grid>
                                    <Grid item xs={3} style={{ fontSize: 12, background: idx % 2 == 0 ? '' : '#efefef' }}>
                                        {vasaloppetDateTimeGetterFormatter(o.orderDate)}
                                    </Grid>
                                </Fragment>
                            })}
                        </Grid>
                    }
                </CardContent>
            </Card>
            {editOrderId &&
                <ManageOrder orderId={editOrderId}
                    close={handleEditClose}
                />
            }
        </>);
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(OrdersWithPaidMismatchCard);
