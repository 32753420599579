import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid/Grid";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import { ApiBackend } from "../../../providers/apibackend";
import LinearProgress from "@material-ui/core/LinearProgress";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Person } from "../../../model/Person";
import EditPersonDialog from "../../Entries/Person/EditPersonDialog";
import { IconButton, Typography } from "@material-ui/core";
import { nameFormatter } from "./utils";

type EntryPerson = Pick<Person, "id" | "firstName" | "lastName"> & { eventKeys: string; };

const EntryPersonsMissingNationality = (props: WithStyles) => {
    const [result, setResult] = useState<EntryPerson[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [editPersonId, setEditPersonId] = useState<string | null>(null);

    const api = new ApiBackend();

    useEffect(() => {
        updateList();
    }, []);

    const updateList = async () => {
        setLoading(true);
        const data = await api.getEntryPersonsMissingNationality();
        setResult(data);
        setLoading(false);
    };

    const handleItemClick = (id: string) => {
        setEditPersonId(id);
    };

    const handleEditAbort = async () => {
        setEditPersonId(null);
    };

    const handleEditSave = async () => {
        setEditPersonId(null);
        await updateList();
    };

    const render = () => {
        const { classes } = props;

        return (<>
            <Card>
                <CardHeader className={classes.cardHeader}
                    title={<>
                        <Typography variant="h5" style={{ display: "inline" }}>Saknar nationalitet</Typography>
                        <IconButton onClick={updateList}>
                            <RefreshIcon color="primary" />
                        </IconButton>
                    </>}
                />
                <CardContent>
                    <p>
                        Deltagare som saknar nationalitet
                    </p>

                    {loading &&
                        <LinearProgress color="secondary" />
                    }
                    {result &&
                        <Grid container>
                            <Grid item xs={6} style={{ fontWeight: "bold" }}>Namn</Grid>
                            <Grid item xs={6} style={{ fontWeight: "bold" }}>Lopp</Grid>
                            {result.map((o, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        <Grid item xs={6} style={{ fontSize: 12, background: idx % 2 == 0 ? "" : "#efefef" }}>
                                            <a style={{ textDecoration: "underline" }} href="#"
                                                onClick={() => {
                                                    handleItemClick(o.id);
                                                }}
                                            >
                                                {nameFormatter(o)}
                                            </a>
                                        </Grid>
                                        <Grid item xs={6} style={{ fontSize: 12, background: idx % 2 == 0 ? "" : "#efefef" }}>
                                            {o.eventKeys}
                                        </Grid>
                                    </Fragment>
                                );
                            })}
                        </Grid>
                    }
                </CardContent>
            </Card>
            {editPersonId &&
                <EditPersonDialog personId={editPersonId}
                    onAbortEdit={handleEditAbort}
                    onSave={handleEditSave}
                />
            }
        </>);
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(EntryPersonsMissingNationality);
