import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Event } from "../../../model/Event";
import { ApiBackend } from "../../../providers/apibackend";
import ClearableSelect from "../ClearableSelect";

interface IProps {
    label: string;
    useWildCardSelect?: boolean;
    clearable: boolean;
    initialValue?: string;
    onChange: (nextValue?: string, eventName?: string) => void;
}

const EventIdFilter = ({ label, useWildCardSelect, clearable, initialValue, onChange }: IProps) => {
    const [events, setEvents] = useState(null as Event[]);
    const [eventId, setEventId] = useState(initialValue);
    const wildCardSelect = "ALL";
    const noValue = "NONE";
    const didMount = useRef<boolean>(false);

    const backend = new ApiBackend();

    useEffect(() => {
        didMount.current = true;

        const init = async (): Promise<void> => {
            const res = await backend.listEvents();
            if (didMount.current) {
                setEvents(res.sort((a: Event, b: Event) => a.name.localeCompare(b.name, "sv")))
            }
        };

        init();

        // unmount
        return () => { didMount.current = false; };
    }, []);

    useEffect(() => {
        if (!didMount.current || !events) {
            return;
        }

        notifyChange();
    }, [eventId]);

    const notifyChange = debounce(() => {
        if (!didMount.current) {
            return;
        }

        const nextValue = eventId === wildCardSelect ? null : eventId;
        let eventName: string;

        if (!!nextValue) {
            eventName = events.find(x => x.id === nextValue).name;
        }

        onChange(nextValue, eventName);
    }, 200);

    const safeValue = () => {
        if (events && !!eventId) {
            return eventId;
        }

        return useWildCardSelect ? wildCardSelect : noValue;
    };

    return (
        <FormControl fullWidth>
            <InputLabel shrink>{label}</InputLabel>
            <ClearableSelect
                clearable={clearable}
                value={safeValue()}
                onClear={() => {
                    setEventId(null);
                }}
                onChange={(evt: any) => {
                    setEventId(evt.target.value);
                }}
            >
                {useWildCardSelect &&
                    <MenuItem key={wildCardSelect} value={wildCardSelect}>Alla</MenuItem>
                }
                {!useWildCardSelect && !eventId &&
                    <MenuItem key={noValue} value={noValue}>Välj</MenuItem>
                }
                {events?.map((x, idx) => {
                    return <MenuItem key={idx} value={x.id}>{x.name}</MenuItem>;
                })}
            </ClearableSelect>
        </FormControl>
    );
}
export default EventIdFilter;
