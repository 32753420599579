import { Grid } from "@material-ui/core";
import React from "react";
import OrdersWithPaidMismatchCard from "./cards/OrdersWithPaidMismatchCard";
import EntriesWithWrongClub from "./cards/EntriesWithWrongClub";
import EntryPersonsMissingBirthdate from "./cards/EntryPersonsMissingBirthdate";
import EntryPersonsMissingNationality from "./cards/EntryPersonsMissingNationality";
import CvtaEntriesWithWrongClub from "./cards/CvtaEntriesWithWrongClub";
import EntryPersonsDuplicateEntries from "./cards/EntryPersonsDuplicateEntries";
import EntriesWithWrongAge from "./cards/EntriesWithWrongAge";
import EntriesWithWrongSex from "./cards/EntriesWithWrongSex";

const ManageDeviations = () => {

    const render = () => {
        return (<>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <OrdersWithPaidMismatchCard />
                </Grid>
                <Grid item md={6} xs={12}>
                    <EntriesWithWrongClub />
                </Grid>
                <Grid item md={6} xs={12}>
                    <CvtaEntriesWithWrongClub />
                </Grid>
                <Grid item md={6} xs={12}>
                    <EntryPersonsMissingBirthdate />
                </Grid>
                <Grid item md={6} xs={12}>
                    <EntryPersonsMissingNationality />
                </Grid>
                <Grid item md={6} xs={12}>
                    <EntryPersonsDuplicateEntries />
                </Grid>
                <Grid item md={6} xs={12}>
                    <EntriesWithWrongAge />
                </Grid>
                <Grid item md={6} xs={12}>
                    <EntriesWithWrongSex />
                </Grid>
            </Grid>
        </>);
    };

    return render();
};

export default ManageDeviations;