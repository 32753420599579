import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid/Grid";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useState } from "react";
import { ApiBackend } from "../../../providers/apibackend";
import LinearProgress from "@material-ui/core/LinearProgress";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditEntryDialog from "../../Entries/Entry/EditEntryDialog";
import { Entry } from "../../../model/Entry";
import { IconButton, Typography } from "@material-ui/core";
import { nameFormatter } from "./utils";
import { getDateOfBirthAsString } from "../../../utilities/EntryHelpers";

const EntriesWithWrongAge = (props: WithStyles) => {
    const [result, setResult] = useState<Entry[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [editEntryId, setEditEntryId] = useState<string | null>(null);

    const api = new ApiBackend();

    useEffect(() => {
        updateList();
    }, []);

    const updateList = async () => {
        setLoading(true);
        const data = await api.getEntriesWithWrongAge();
        setResult(data);
        setLoading(false);
    };

    const handleItemClick = (id: string) => {
        setEditEntryId(id);
    };

    const handleEditAbort = async () => {
        setEditEntryId(null);
    };

    const handleEditSave = async () => {
        setEditEntryId(null);
        await updateList();
    };

    const render = () => {
        const { classes } = props;

        return (<>
            <Card>
                <CardHeader className={classes.cardHeader}
                    title={<>
                        <Typography variant="h5" style={{ display: "inline" }}>Felaktig ålder</Typography>
                        <IconButton onClick={updateList}>
                            <RefreshIcon color="primary" />
                        </IconButton>
                    </>}
                />
                <CardContent>
                    <p>
                        Deltagare i lopp där ålder är felaktig
                    </p>

                    {loading && <LinearProgress color="secondary" />
                    }
                    {result &&
                        <Grid container>
                            <Grid item xs={4} style={{ fontWeight: "bold" }}>Namn</Grid>
                            <Grid item xs={4} style={{ fontWeight: "bold" }}>Lopp</Grid>
                            <Grid item xs={4} style={{ fontWeight: "bold" }}>Angivet födelsedatum</Grid>
                            {result.map((o, idx) => {
                                return <Fragment key={idx}>
                                    <Grid item xs={4} style={{ fontSize: 12, background: idx % 2 == 0 ? "" : "#efefef" }}>
                                        <a style={{ textDecoration: "underline" }} href="#"
                                            onClick={() => {
                                                handleItemClick(o.id)
                                            }}
                                        >
                                            {nameFormatter(o)}
                                        </a>
                                    </Grid>
                                    <Grid item xs={4} style={{ fontSize: 12, background: idx % 2 == 0 ? "" : "#efefef" }}>
                                        {o.eventKey}
                                    </Grid>
                                    <Grid item xs={4} style={{ fontSize: 12, background: idx % 2 == 0 ? "" : "#efefef" }}>
                                        {getDateOfBirthAsString(o)}
                                    </Grid>
                                </Fragment>
                            })}
                        </Grid>
                    }
                </CardContent>
            </Card>
            {editEntryId &&
                <EditEntryDialog entryId={editEntryId}
                    onAbortEdit={handleEditAbort}
                    onSave={handleEditSave}
                />
            }
        </>);
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(EntriesWithWrongAge);
